<template>
  <div>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="limit"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-input
            class="d-inline-block mr-1"
            placeholder="Search..."
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center mt-1 mt-md-0 justify-content-center justify-content-md-end mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-link
              :to="{ name: 'NewPresenter' }"
              class="font-weight-bold d-block text-nowrap"
            >
              <span class="text-nowrap">Create New Presenter</span>
            </b-link>
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="options.items"
      responsive
      :fields="options.columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(image)="data">
        <img
          style="width: 60px; height: 60px;"
          class="rounded-circle"
          :src="data.item.image"
          alt="profile image"
        >
      </template>
      <template #cell(socialLinks)="data">
        <span
          v-for="(social, index) in Object.keys(data.item.socialLinks)"
          v-show="data.item.socialLinks[social] !== '' && data.item.socialLinks[social] !== null"
          :key="index"
        >
          <a
            :href="data.item.socialLinks[social]"
            target="_blank"
          >
            {{ social.toUpperCase() }}
          </a>
        </span>
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-for="(item,index) in data.item.actions"
            :key="index"
            @click="$emit(item.on, data.item.id)"
          >
            {{ item.label }}
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ 1 }} to {{ 5 }} of {{ 20 }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BTable,
  BPagination, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BDropdownItem,
    BPagination,
    BLink,
    BRow,
    BCol,
    BFormInput,
    BTable,
    vSelect,
    BDropdown,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      limit: 10,
      currentPage: 1,
      perPageOptions: [10, 20, 30, 40, 50],
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
