<template>
  <b-card>
    <radio-table
      :options="options"
      @delete="deletePresenter"
      @edit="editPresenter"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import RadioTable from '../../components/table/RadioTable.vue'
import presenterStoreModule from '../presentersStoreModule'

export default {
  name: 'PresentersList',
  components: {
    RadioTable,
    BCard,
  },
  data() {
    return {
      storeName: 'presenters',
      presenters: null,
    }
  },
  computed: {
    options() {
      return {
        items: this.presenters,
        columns: [
          {
            key: 'image', label: 'Profile Image', sortable: false, type: 'image',
          },
          { key: 'name', label: 'Name', sortable: true },
          { key: 'bio', label: 'Bio', sortable: true },
          {
            key: 'socialLinks', label: 'Social Links', sortable: false,
          },
          { key: 'actions' },
        ],
      }
    },
  },
  async mounted() {
    await this.registerStore()
    await this.fetchPresenters()
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fetchPresenters() {
      const data = await this.$store.dispatch(`${this.storeName}/fetchPresenters`, { limit: '10', page: 1, q: '' })
      this.presenters = data.map(
        presenter => {
          const social = JSON.parse(presenter.socialLinks)
          return {
            ...presenter,
            image: presenter.profileImage ? presenter.profileImage : 'https://via.placeholder.com/150',
            socialLinks: social,
            actions: [
              {
                label: 'Edit',
                on: 'edit',
              },
              {
                label: 'Delete',
                on: 'delete',
              },
            ],
          }
        },
      )
    },
    async deletePresenter($event) {
      try {
        await this.$store.dispatch(`${this.storeName}/deletePresenter`, $event)
        this.$bvToast.toast('Presenter deleted.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        this.fetchPresenters()
      } catch (error) {
        this.$bvToast.toast('Getting an error while deleting presenter.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    editPresenter(id) {
      console.log('edit', id)
      this.$router.push({ name: 'EditPresenter', query: { id } })
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, presenterStoreModule)
    },
  },
}
</script>

<style>

</style>
